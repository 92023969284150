import axios from 'axios'
import {router} from '@/router'
import Vue from 'vue'

const $api = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 0,
  headers: {
    Accept: 'application/json',
  },
})

$api.defaults.headers.get['Accept-Language'] = 'ru'
$api.defaults.headers.get['Accept'] = 'application/json'
$api.defaults.headers.get['Content-Type'] = 'application/json'
$api.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
$api.defaults.headers.get['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
$api.defaults.headers.get['Access-Control-Allow-Credentials'] = true

$api.defaults.headers.post['Accept-Language'] = 'ru'
$api.defaults.headers.post['Content-Type'] = 'application/json'
$api.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
$api.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
$api.defaults.headers.post['Access-Control-Allow-Credentials'] = true

export default $api

$api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // const currentUrl = router.history.current.path
    // if (error.response.status === 401 && currentUrl !== '/login') router.push('/login')
    console.log(error)
    handlerErrors(error)
    return Promise.reject(error)
  }
)

export const auth = () => {
  let accessToken = localStorage.getItem('accessToken')
  return accessToken ? {Authorization: 'Bearer ' + accessToken} : {}
}

export const handlerErrors = ({response, request, message}) => {
  const currentUrl = router.history.current.path
  const errorMessage = []
  const showToast = text => {
    Vue.$toast.error(text, {
      timeout: 10000,
      closeButton: 'button',
      progressBar: true,
    })
  }

  const statusHandler = (status, isMessage, errors, isErrors) => {
    console.log(status, isMessage, isErrors)
    switch (status) {
      case 401:
        if (currentUrl === '/login') {
          showToast('Данные пользователя не найдены (401).')
        }
        break
      case 404:
        showToast('Запрашиваемая страница не найдена (404).')
        break
      case 500:
        showToast('Внутренняя Ошибка Сервера (500).')
        break
      default:
        if (currentUrl !== '/login') {
          if (isMessage) {
            errorMessage.push(`Message: ${isMessage}`)
          }
          if (isErrors) {
            errorMessage.push(`Errors: ${JSON.stringify(errors)}`)
          }
        }

        if (!isMessage && !isErrors) {
          showToast(`Возникла ошибка, обратитесь к администратору. Код ошибки: ${status}`)
        } else {
          showToast(errorMessage.join('\n\n'))
        }
    }
  }

  if (response) {
    const {status, data} = response
    const isMessage = data?.message
    const isErrors = Object.keys(data?.errors).length > 0
    statusHandler(status, isMessage, data?.errors, isErrors)
  } else if (request) {
    const {status, response} = request
    const isMessage = response.data?.message
    const isErrors = Object.keys(response.data?.errors).length > 0
    statusHandler(status, isMessage, response.data?.errors, isErrors)
  } else {
    // Handle setup errors
  }
}
