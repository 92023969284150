import {
    Tree,
    Table,
    TableColumn,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import Vue from "vue";

Vue.component(Tree.name, Tree)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
require("@/assets/scss/tree.css")
